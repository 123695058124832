'use client'

import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useState } from 'react'

import { LanguageToggles } from '@/layouts/NavBar'
import Icon from '@ui/Icon'

export type AuthNavbarProps = {
  backLabel: string
}

const AuthNavbar: React.FC<AuthNavbarProps> = ({ backLabel }) => {
  const router = useRouter()
  const [isLanguageOpen, setIsLanguageOpen] = useState(false)
  const { i18n } = useTranslation()
  const currentLanguage = i18n.language.toUpperCase()

  return (
    <>
      <div className='flex items-center gap-2.5'>
        <Image
          src='/icons/chevron-left.svg'
          height={24}
          width={24}
          alt='chevron-left'
          onClick={() => router.back()}
        />
        <span className='font-bold'>{backLabel}</span>
      </div>
      <div
        className='flex gap-2 justify-center items-center font-bold text-sm cursor-pointer hover:bg-[#F4F4F4] rounded-xl p-[5px]'
        onClick={() => setIsLanguageOpen((prev) => !prev)}
      >
        <Icon iconSrc={'/icons/ic-globe.svg'} width={24} height={24} alt='chevron' />
        <div>{currentLanguage}</div>

        {isLanguageOpen && (
          <div
            className={clsx(
              'z-30 absolute top-[68px] right-[16px]',
              'bg-white rounded-lg shadow-[0px_4px_14px_0px_rgba(0,0,0,0.15)]'
            )}
          >
            <LanguageToggles />
          </div>
        )}
      </div>
    </>
  )
}

export default AuthNavbar
