import clsx from 'clsx'
import { useFormik } from 'formik'
import { GetServerSideProps, InferGetServerSidePropsType } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { ReactElement, useEffect } from 'react'
import useSWRMutation from 'swr/mutation'

import AuthNavbar from '@/components/common/AuthNavbar'
import type { SWRArgument } from '@/types/common'
import { LoginSchema } from '@lib/schemas/LoginFormSchema'
import { MaterialInput } from '@ui/MaterialInput'
import WifButton from '@ui/WifButton'
import { AuthContext } from 'src/providers/AuthProvider'
import type { NextPageWithLayout } from '../_app'

async function sendRequest(url: string, { arg }: SWRArgument<{ email: string; password: string }>) {
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(arg),
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res.json())
}

const LoginNavBar: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className='flex items-center justify-between w-full h-[68px] px-4'>
      <AuthNavbar backLabel={t('Word_Login')} />
    </div>
  )
}

const LoginPage: NextPageWithLayout<{ from: string; redirectURL?: string }> = ({
  from,
  redirectURL,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const router = useRouter()
  const { setAuthToken, removeTokens } = React.useContext(AuthContext)
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const { trigger, isMutating } = useSWRMutation('/api/maas/login', sendRequest)

  const onLogin = async () => {
    setIsLoading(true)
    setErrorMessage('')
    try {
      const result = await trigger({
        email: formik.values.email,
        password: formik.values.password,
      })

      if (result?.data.statusCode === 'USR-001') {
        setIsLoading(false)
        return setErrorMessage(t('auth:Error.Unidentified_User')!)
      }

      if (result.code === 422) {
        setIsLoading(false)
        return setErrorMessage(t('auth:Error.Unidentified_User')!)
      }

      if (result.code === 500) {
        setIsLoading(false)
        return setErrorMessage(t('auth:Error.InternalServer')!)
      }

      if (!result.code) {
        setAuthToken(result.data.token)
        setIsLoading(false)
        router.push('/')
      }
    } catch (err) {
      console.error(err)
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: onLogin,
    validationSchema: LoginSchema,
  })

  useEffect(() => {
    removeTokens()
  }, [])

  return (
    <div className={clsx('grid grid-cols-[1fr] grid-rows-[68px_1fr] h-screen')}>
      <LoginNavBar />

      <div
        className={clsx(
          'h-full overflow-y-auto min-h-[calc(100vh-60px-32px)]',
          'xs:min-h-[calc(100vh-90px-64px)] xs:pb-0'
        )}
      >
        <div
          className={clsx(
            'relative flex h-full items-center justify-center overflow-y-auto',
            'bg-gray-400 bg-[url("/images/register-bg.jpg")] bg-cover bg-center bg-no-repeat'
          )}
        >
          <div
            className={clsx(
              'flex w-full flex-col justify-start rounded-3xl bg-white sm:max-w-[430px] sm:px-4 sm:pb-9 ',
              'mb-4 mt-10 max-w-[90%] px-2 pb-6'
            )}
          >
            <div className='mb-8 mt-4 flex items-center justify-center'>
              <Link href={'/'}>
                <Image src='/icons/wifkain.svg' alt='Wif Logo' height={44} width={116} />
              </Link>
            </div>

            <form onSubmit={formik.handleSubmit} data-test='login-form' className='mt-8'>
              <MaterialInput
                name='email'
                label='Email'
                type='email'
                value={formik.values.email}
                error={!!formik.errors?.email && !!formik.touched?.email}
                errorMessage={t(formik.errors.email!)!}
                icon='/icons/ic-mail.svg'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <MaterialInput
                name='password'
                label='Password'
                type='password'
                value={formik.values.password}
                error={!!formik.errors?.password && !!formik.touched?.password}
                errorMessage={t(formik.errors.password!)!}
                icon='/icons/ic-key.svg'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />

              <WifButton
                id='login'
                text='Login'
                submitType='submit'
                handleClick={() => {}}
                disabled={!(formik.isValid && formik.dirty)}
                isLoading={isLoading}
              />

              {!!errorMessage && (
                <span className='mt-1 text-xs font-semibold text-error-red'>
                  {t(errorMessage, { interpolation: { alwaysFormat: true } })}
                </span>
              )}

              <div className='mt-16 flex w-full items-center justify-center gap-6'>
                <span>{t('auth:Dont_Have_Account')}</span>
                <Link
                  href='/register'
                  className='font-bold text-wif-orange'
                  data-test='login-link-register'
                >
                  Register
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

LoginPage.getLayout = (page: ReactElement) => {
  return <>{page}</>
}

export const getServerSideProps: GetServerSideProps = async ({ locale, req, query }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale!, ['auth', 'common'])),
      from: req.headers.referer || '',
      redirectURL: query?.from || '',
    },
  }
}

export default LoginPage
