import { Html5InputType } from '@/types/ui'
import clsx from 'clsx'
import Image from 'next/image'
import { ChangeEventHandler, FocusEventHandler, useState } from 'react'

interface MaterialInputProps {
  label: string
  type: Html5InputType
  error: boolean
  errorMessage: string | undefined | null
  icon?: string
  name?: string
  value: any
  onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>
  onBlur: FocusEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  disabled?: boolean
}

export const MaterialInput = ({
  label,
  type,
  error,
  errorMessage,
  icon,
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  disabled = false,
}: MaterialInputProps) => {
  const [isPassShown, setIsPassShown] = useState(false)

  const togglePassReveal = () => {
    setIsPassShown(!isPassShown)
  }

  const renderInputType = (type: Html5InputType) => {
    if (type === 'password') {
      return isPassShown ? 'text' : 'password'
    }
    return type
  }

  return (
    <div className={clsx('relative', 'mb-11', icon && 'pl-11')}>
      {icon && (
        <div className='icon-placeholder absolute top-1/2 -translate-y-1/2 leading-[0px] left-0 pb-4'>
          <Image src={icon} alt={label + ' logo'} width={30} height={30} />
        </div>
      )}

      {type === 'password' && (
        <div
          className='input-icon absolute right-0 cursor-pointer z-30'
          onClick={togglePassReveal}
          data-testid={`form-reveal-${name}`.toLowerCase()}
        >
          <Image
            src={isPassShown ? '/icons/ic-pass-show.svg' : '/icons/ic-pass-hide.svg'}
            alt='Password reveal'
            width={28}
            height={28}
          />
        </div>
      )}

      {(type === 'text' || type === 'password' || type === 'email') && (
        <input
          id={name}
          name={name}
          type={renderInputType(type)}
          className={clsx(
            'pb-2',
            'peer',
            'border-b',
            'text-sm',
            'w-full',
            'font-bold',
            'block',
            'focus:outline-none',
            'bg-transparent',
            'relative',
            error ? 'border-error-red' : 'border-medium-gray',
            disabled ? 'text-silver-gray' : 'text-[#303030]'
          )}
          value={value}
          autoComplete={label}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          required
          disabled={disabled}
          data-testid={`form-input-${name}`.toLowerCase()}
        />
      )}

      <label
        htmlFor={name}
        className={clsx([
          'capitalize',
          'text-sm',
          'font-normal',
          'absolute',
          'pointer-events-none',
          'top-0',
          'ease-out',
          'duration-200',
          'transition-all',
          'text-dark-grey',
          'peer-focus:-top-5',
          'peer-focus:!text-xs',
          'peer-focus:text-dark-grey',
          'peer-valid:-top-5',
          'peer-valid:!text-xs',
          'peer-valid:text-dark-grey',
          { '!-top-5': value },
          { '!text-xs': value },
          { 'text-dark-grey': value },
        ])}
        data-test={`label-${name}`}
      >
        {label}
      </label>

      {error && <p className='text-error-red text-xs font-normal'>{errorMessage}</p>}
    </div>
  )
}
